import { configureStore } from "@reduxjs/toolkit";

import CompanyReducer from "./Slice/companyInfoSlice";
import PreConsiderPointReducer from "./Slice/preConsiderPointInfoSlice";
import DesiredContractTypeReducer from "./Slice/desiredContractType";
import DesiredContractVltReducer from "./Slice/desiredContractVltSlice";
import DesiredContractInfoReducer from "./Slice/desiredContractInfo";
import LowLightReducer from "./Slice/lowLight";
import LowPowerReducer from "./Slice/lowPower";
import LowSnowMeltReducer from "./Slice/lowSnowmelt";
import HighAlwaysReducer from "./Slice/highAlways";
import HighReserveReducer from "./Slice/highReserve";
import ExtraHighAlwaysReducer from "./Slice/extraHighAlways";
import ExtraHighReserveReducer from "./Slice/extraHighReserve";
import specialDeviceReducer from "./Slice/specialDevice";
import keitouReducer from "./Slice/keitou";
import considerationItemReducer from "./Slice/considerationItem";
import uploadFilesReducer from "./Slice/uploadFiles";

export const store = configureStore({
  reducer: {
    company: CompanyReducer,
    preConsiderPointInfo: PreConsiderPointReducer,
    desiredContractType: DesiredContractTypeReducer,
    desiredContractVlt: DesiredContractVltReducer,
    desiredContractInfo: DesiredContractInfoReducer,
    lowLight: LowLightReducer,
    lowPower: LowPowerReducer,
    lowSnowMelt: LowSnowMeltReducer,
    highAlways: HighAlwaysReducer,
    highReserve: HighReserveReducer,
    exhighAlways: ExtraHighAlwaysReducer,
    exhighReserve: ExtraHighReserveReducer,
    specialDevice: specialDeviceReducer,
    keitou: keitouReducer,
    considerationItem: considerationItemReducer,
    uploadFiles: uploadFilesReducer,
  },
});
