import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Snackbar,
  Alert,
  Link,
} from "@mui/material";
import { CircularProgress, Backdrop } from "@mui/material";

//import Amplify, { Auth } from "aws-amplify";
import { API, graphqlOperation } from "@aws-amplify/api";
//Amplify
import { Storage } from "aws-amplify";

import { createEntryForm, createUserMailCompany } from "../graphql/mutations";

import CompanyInfo from "./EntryForms/CompanyInfo";
import PreConsiderPointInfo from "./EntryForms/PreConsiderPointInfo";
import DesiredContractDetails from "./EntryForms/DesiredContractDetails";
import ConsiderationItem from "./EntryForms/ConsiderationItem";
import FileUpload from "./EntryForms/FileUpload";

import {
  clearData as companyClearData,
  validate as companyValidate,
  setError as companySetError,
  setMessage as companySetMessage,
} from "../Store/Slice/companyInfoSlice";
import {
  clearData as preConsiderPointInfoClearData,
  validate as preConsiderPointInfoValidate,
  setError as preConsiderSetError,
  setMessage as preConsiderSetMessage,
} from "../Store/Slice/preConsiderPointInfoSlice";
import {
  clearData as desiredContractTypeClearData,
  validate as desiredContractTypeValidate,
} from "../Store/Slice/desiredContractType";
import { clearData as desiredContractInfoClearData } from "../Store/Slice/desiredContractInfo";
import {
  clearData as desiredContractVltClearData,
  validate as desiredContractVltValidate,
} from "../Store/Slice/desiredContractVltSlice";
import { clearData as lowLightClearData } from "../Store/Slice/lowLight";
import { clearData as lowPowerClearData } from "../Store/Slice/lowPower";
import { clearData as lowSnowmeltClearData } from "../Store/Slice/lowSnowmelt";
import { clearData as highAlwaysClearData } from "../Store/Slice/highAlways";
import { clearData as highReserveClearData } from "../Store/Slice/highReserve";
import { clearData as specialDeviceClearData } from "../Store/Slice/specialDevice";
import { clearData as keitouClearData } from "../Store/Slice/keitou";
import {
  clearData as considerationItemClearData,
  validate as considerationItemValidate,
} from "../Store/Slice/considerationItem";
import { clearData as uploadFilesClearData } from "../Store/Slice/uploadFiles";

import { store } from "../Store/store";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import dayjs from "dayjs";

//アップロードファイル
let upLoadFileArray = new Array(5);

function EntryForm(props) {
  const navigate = useNavigate();
  const formData = useSelector((state) => state);
  const dispatch = useDispatch();
  const app_env = `${process.env.REACT_APP_ENV}`;

  //初期表示時
  useEffect(() => {
    //logger.info("useEffect", "Start");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function storeData() {
    //logger.info("storeData", "Start");

    let pushData = {
      id: formData.company.key_uuid,
      mail: formData.company.mailAddress,
      form_data: JSON.stringify(formData),
      tmstmp: dayjs().format(),
    };
    let pushCompanyData = {
      mail: formData.company.mailAddress,
      tmstmp: dayjs().format(),
      company_name: formData.company.company_name,
      postCd: formData.company.postCd,
      address: formData.company.address,
      tel: formData.company.tel,
      personInChargeName: formData.company.PersonInChargeName,
      mobilePhone: formData.company.mobilePhone,
    };
    try {
      let ret = await API.graphql(
        graphqlOperation(createEntryForm, { input: pushData })
      );
      //console.log(ret);
      props.logger.info(
        props.user.attributes.email,
        "申込登録結果:" + JSON.stringify(ret)
      );
      ret = await API.graphql(
        graphqlOperation(createUserMailCompany, { input: pushCompanyData })
      );
      //console.log(ret);
      props.logger.info(
        props.user.attributes.email,
        "申込ユーザ情報登録結果:" + JSON.stringify(ret)
      );
    } catch (e) {
      console.log(e);
      props.logger.error(
        props.user.attributes.email,
        "storeData:" + JSON.stringify(e)
      );
      throw e;
    }
  }
  function clearAllData() {
    //データ初期化
    dispatch(companyClearData());
    dispatch(preConsiderPointInfoClearData());
    dispatch(desiredContractTypeClearData());
    dispatch(desiredContractVltClearData());
    dispatch(desiredContractInfoClearData());
    dispatch(lowLightClearData());
    dispatch(lowPowerClearData());
    dispatch(lowSnowmeltClearData());
    dispatch(highAlwaysClearData());
    dispatch(highReserveClearData());
    dispatch(specialDeviceClearData());
    dispatch(keitouClearData());
    dispatch(considerationItemClearData());
    dispatch(uploadFilesClearData());
    upLoadFileArray.splice(0);
  }
  //ファイルアップロード処理
  async function uploadFiles() {
    //logger.info("uploadFiles", "Start");

    for (let i = 0; i < formData.uploadFiles.length; i++) {
      if (
        formData.uploadFiles[i].s3_key &&
        formData.uploadFiles[i].s3_key.length > 0
      ) {
        //console.log(i + ":" + upLoadFileArray[i]);
        try {
          const result = await Storage.put(
            formData.uploadFiles[i].s3_key,
            upLoadFileArray[i],
            {
              level: "public",
              contentType: formData.uploadFiles[i].type,
            }
          );
          if (app_env !== "production") {
            //本番以外でファイル名が以下だったらデバッグ例外を発生させる
            if (
              formData.uploadFiles[i].fileName.indexOf(
                "エラーになるファイル"
              ) === 0
            ) {
              throw new Error(
                "テストエラー発生：" + formData.uploadFiles[i].fileName
              );
            }
          }

          //console.log(result);
          props.logger.info(
            props.user.attributes.email,
            "ファイルアップロード結果:" +
              formData.uploadFiles[i].fileName +
              ":" +
              JSON.stringify(result)
          );
        } catch (e) {
          console.log(e);
          props.logger.error(
            props.user.attributes.email,
            "storeData:" + JSON.stringify(e)
          );
          throw e;
        }
      }
    }
  }

  //送信ボタン押下時処理
  async function sendData(e) {
    setConfirmOpen(false);
    setProgress(true);
    try {
      //ファイルアップロード
      await uploadFiles();
      //データ登録
      await storeData();
      setProgress(false);
      navigate("/thanks");
    } catch (e) {
      console.log(e);
      props.logger.error(
        props.user.attributes.email,
        "sendData:" + JSON.stringify(e)
      );
      clearAllData();
      setProgress(false);
      navigate("/error");
    }
  }

  //入力内容チェック制御
  function checkFormData() {
    props.logger.info(props.user.attributes.email, "checkFormData:Start");

    if (isFormError() === true) {
      setErrorOpen(true);
    } else {
      setDialogTitle("供給側接続事前検討の申込");
      setDialogMessage(
        "入力されている内容で供給側接続事前検討の申込を行いますか？"
      );
      //setDialogOkAction(btnSendClick);
      setConfirmOpen(true);
    }
  }

  //エラーチェック
  function isFormError() {
    //logger.info("isFormError", "Start");

    //エラーチェック
    dispatch(companyValidate());
    dispatch(preConsiderPointInfoValidate());
    dispatch(desiredContractVltValidate());
    dispatch(desiredContractTypeValidate());
    dispatch(considerationItemValidate());

    const state = store.getState();
    //console.log(state);
    //会社情報
    if (
      Object.entries(state.company.error).filter((val) => {
        return val[1];
      }).length > 0
    ) {
      return true;
    }
    //地点情報
    if (
      Object.entries(state.preConsiderPointInfo.error).filter((val) => {
        return val[1];
      }).length > 0
    ) {
      return true;
    }
    //3.お申込み希望契約内容
    if (
      Object.entries(state.desiredContractVlt.low.entory_info.error).filter(
        (val) => {
          return val[1];
        }
      ).length > 0
    ) {
      return true;
    }
    if (
      Object.entries(state.desiredContractVlt.high.entory_info.error).filter(
        (val) => {
          return val[1];
        }
      ).length > 0
    ) {
      return true;
    }
    if (
      Object.entries(state.desiredContractType.error).filter((val) => {
        return val[1];
      }).length > 0
    ) {
      return true;
    }
    if (
      Object.entries(state.considerationItem.error).filter((val) => {
        return val[1];
      }).length > 0
    ) {
      return true;
    }

    //工事会社等の申込エリア必須
    if (
      state.company.company_type === "kouji" &&
      state.preConsiderPointInfo.offer_area.length === 0
    ) {
      dispatch(preConsiderSetError({ offer_area: true }));
      dispatch(
        preConsiderSetMessage({
          offer_area: "お申込みエリアを選択してください。",
        })
      );
      return true;
    }

    //設備変更の場合、委任チェック（工事会社等）、地点番号（１）必須
    if (
      state.desiredContractVlt.low.entory_info.entory_contents === "設備変更" ||
      state.desiredContractVlt.high.entory_info.entory_contents === "設備変更"
    ) {
      //工事会社等の場合の委任チェック
      if (
        state.company.company_type === "kouji" &&
        state.company.lowDelegation === false
      ) {
        dispatch(companySetError({ lowDelegation: true }));
        dispatch(
          companySetMessage({
            lowDelegation: "小売電気事業者さまの承諾が必要です。",
          })
        );
        return true;
      }
      //地点番号１の入力チェック
      if (state.preConsiderPointInfo.supply_no_1.length === 0) {
        dispatch(preConsiderSetError({ supply_no_1: true }));
        dispatch(
          preConsiderSetMessage({
            supply_no_1: "設備変更対象の供給地点特定番号を入力してください。",
          })
        );
        return true;
      }
    }
    //高圧－新設－系統連系有の受付不可
    if (
      state.desiredContractVlt.high.entory_info.entory_contents === "新設" &&
      state.desiredContractInfo.keitou === "有"
    ) {
      //逆潮流＝有の場合エラー
      if (
        state.keitou[0].keitou_reverse_flow === "有" ||
        state.keitou[1].keitou_reverse_flow === "有" ||
        state.keitou[2].keitou_reverse_flow === "有"
      ) {
        setErrorDialogOpen(true);
        setErrorDialogContent(error_01);
        return true;
      }
    }
    return false;
  }

  //確認ダイアログ
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleClose = (e) => {
    switch (dialogTitle) {
      case "供給側接続事前検討の申込":
        setConfirmOpen(false);
        break;
      case "キャンセル確認":
        setConfirmOpen(false);
        break;
      default:
    }
  };
  //エラーダイアログ
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const handleErrorDialogClose = (e) => {
    setErrorDialogOpen(false);
  };
  const [errorDialogContent, setErrorDialogContent] = useState("");
  //エラー内容１
  const error_01 = function () {
    return (
      <DialogContentText>
        ※「高圧－新設申込－系統連系有－逆潮流有」の申込みは、受電側接続検討に該当するため、ＷＥＢ申込みはできません。
        <br />
        　別途、
        <Link
          href="https://www.hepco.co.jp/network/renewable_energy/fixedprice_purchase/reception.html"
          target="_blank"
        >
          受電側接続検討
        </Link>
        より申込ください。
      </DialogContentText>
    );
  };

  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  //const [dialogOk, setDialogOk] = useState("ＯＫ");
  //const [dialogOkAction, setDialogOkAction] = useState();
  //確認OKボタン処理
  function confirmOkAction() {
    switch (dialogTitle) {
      case "供給側接続事前検討の申込":
        sendData();
        break;
      case "キャンセル確認":
        clearAllData();
        navigate("/");
        break;
      default:
    }
  }
  //キャンセル確認
  function confirmCancel() {
    setDialogTitle("キャンセル確認");
    setDialogMessage("入力されている内容が破棄されますがキャンセルしますか？");
    setConfirmOpen(true);
  }

  //エラーアラート
  const [errorOpen, setErrorOpen] = useState(false);
  function errorHandleClose(e) {
    setErrorOpen(false);
  }
  //処理中
  const [progress, setProgress] = useState(false);

  //アップロードファイル情報
  function setUploadFiles(i, file) {
    //upLoadFileArray.splice(i, 1, file);
    upLoadFileArray[i] = file;
    //console.log(upLoadFileArray);
  }

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={progress}
      >
        <CircularProgress color="secondary" size="8rem" />
      </Backdrop>

      <div style={{ padding: "60px" }}>
        <Card variant="outlined">
          <CardContent>
            <Typography align="left" sx={{}}>
              「*」が付いている項目は必須入力です。
            </Typography>
          </CardContent>
        </Card>
        {/*１．会社情報*/}
        <CompanyInfo accordionexpanded={true} />
        {/*２．検討地点情報*/}
        <PreConsiderPointInfo accordionexpanded={true} />
        {/*３．お申込み希望契約内容*/}
        <DesiredContractDetails accordionexpanded={true} />
        {/*４．検討項目*/}
        <ConsiderationItem accordionexpanded={true} />
        {/*ファイルアップロード*/}
        <FileUpload accordionexpanded={true} setFile={setUploadFiles} />

        {/*最後*/}
        <Card variant="outlined">
          <CardContent>
            <Typography
              align="center"
              sx={{ fontWeight: "bold", textDecoration: "underline" }}
            >
              弊社は、お申込み及びその実施に際して得た情報は、託送供給を実施する目的以外には使用いたしません。
            </Typography>
          </CardContent>
        </Card>
        <Card variant="outlined" sx={{ mt: 2 }}>
          <CardContent>
            <Box textAlign="center">
              <Button
                variant="outlined"
                size="large"
                sx={{ mx: 5 }}
                onClick={confirmCancel}
              >
                キャンセル
              </Button>
              <Button
                variant="contained"
                size="large"
                sx={{ mx: 5 }}
                onClick={checkFormData}
              >
                送信する
              </Button>
            </Box>
          </CardContent>
        </Card>
      </div>
      {/*確認ダイアログ*/}
      <div>
        <Dialog
          open={confirmOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMessage}
              <br />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button id="btn_cancel" onClick={handleClose}>
              キャンセル
            </Button>
            <Button id="btn_ok" onClick={confirmOkAction} autoFocus>
              ＯＫ
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {/*エラーダイアログ*/}
      <div>
        <Dialog
          open={errorDialogOpen}
          onClose={handleErrorDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>エラー</DialogTitle>
          <DialogContent>{errorDialogContent}</DialogContent>
          <DialogActions>
            <Button onClick={handleErrorDialogClose}>ＯＫ</Button>
          </DialogActions>
        </Dialog>
      </div>

      {/*エラー表示*/}
      <div>
        <Snackbar
          open={errorOpen}
          autoHideDuration={6000}
          onClose={errorHandleClose}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        >
          <Alert
            onClose={errorHandleClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            内容にエラーがあります。入力内容を確認してください。
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment>
  );
}

export default React.memo(EntryForm);
