import React from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { clearData as companyClearData } from "../Store/Slice/companyInfoSlice";
import { clearData as preConsiderPointInfoClearData } from "../Store/Slice/preConsiderPointInfoSlice";
import { clearData as desiredContractTypeClearData } from "../Store/Slice/desiredContractType";
import { clearData as desiredContractInfoClearData } from "../Store/Slice/desiredContractInfo";

import { clearData as desiredContractVltClearData } from "../Store/Slice/desiredContractVltSlice";
import { clearData as lowLightClearData } from "../Store/Slice/lowLight";
import { clearData as lowPowerClearData } from "../Store/Slice/lowPower";
import { clearData as lowSnowmeltClearData } from "../Store/Slice/lowSnowmelt";
import { clearData as highAlwaysClearData } from "../Store/Slice/highAlways";
import { clearData as highReserveClearData } from "../Store/Slice/highReserve";
import { clearData as extraHighAlwaysClearData } from "../Store/Slice/extraHighAlways";
import { clearData as extraHighReserveClearData } from "../Store/Slice/extraHighReserve";
import { clearData as specialDeviceClearData } from "../Store/Slice/specialDevice";
import { clearData as keitouClearData } from "../Store/Slice/keitou";
import { clearData as considerationItemClearData } from "../Store/Slice/considerationItem";
import { clearData as uploadFilesClearData } from "../Store/Slice/uploadFiles";

function Thanks(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function thanksOut() {
    clearAllData();
    navigate("/");
    //await props.signOut();
  }

  function clearAllData() {
    //データ初期化
    dispatch(companyClearData());
    dispatch(preConsiderPointInfoClearData());
    dispatch(desiredContractTypeClearData());
    dispatch(desiredContractInfoClearData());
    dispatch(desiredContractVltClearData());
    dispatch(lowLightClearData());
    dispatch(lowPowerClearData());
    dispatch(lowSnowmeltClearData());
    dispatch(highAlwaysClearData());
    dispatch(highReserveClearData());
    dispatch(extraHighAlwaysClearData());
    dispatch(extraHighReserveClearData());
    dispatch(specialDeviceClearData());
    dispatch(keitouClearData());
    dispatch(considerationItemClearData());
    dispatch(uploadFilesClearData());
  }

  return (
    <React.Fragment>
      <div style={{ marginTop: "60px", marginLeft: "20px" }}>
        <Typography>以下の内容で申込を登録しました。</Typography>
        <br />
        <Typography>
          申込の受付処理が開始しましたら、メールにて通知いたします。
        </Typography>
        <Button
          variant="contained"
          size="large"
          onClick={thanksOut}
          style={{ marginTop: "10px" }}
        >
          終了
        </Button>
      </div>
    </React.Fragment>
  );
}

export default Thanks;
